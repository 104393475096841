/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { colors } from '../styles/theme';
import { Layout } from '@components/Layout';
import { useStores } from '../stores/index';
import { Heading } from '@primitives';
// @ts-ignore
import bg from '../resources/images/maintenance_bg.png';
// @ts-ignore
import Logo from '@icons/logo_mobile.svg';

interface IMaintenancePage {

}


const Wrapper = styled.div`
  display: flex;
  flex-direction:column;
  height: 100vh;
  background-image: url(${bg});
  justify-content: center;
  align-items: center;
  background-size: cover;
  padding: 3rem;
`

const Content = styled.div`
  background-color: ${colors.lightBlue};
  max-width: 1024px;
  width: 100%;
  maxHeight: 90vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3rem;
`;

const Title = styled(Heading)`
  margin-top: 3rem;
  margin-bottom: 0;
`;

const MaintenancePage: FC<IMaintenancePage> = observer(() => {
  const {
    commonDataStore: { settings },
  } = useStores();

  return (
    <Layout customLayout>
      <Wrapper>
        <Content>
          <Logo />

          <Title level="1">{settings?.maintenanceTitle}</Title>
          <p>{settings?.maintenanceMessage}</p>
        </Content>
      </Wrapper>
    </Layout >
  );
});

export default MaintenancePage;
